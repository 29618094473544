import React, { useState, useEffect } from 'react';
import {useParams, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ProjectActionTypes } from '../../redux/project/project.types';
import Header from '../../components/home/header/header.component';
import GreenLine from '../../components/greenline/greenline.component';
import FooterNew from '../../components/footernew/footernew.component';
import './iletisim.page.styles.scss';

const IletisimPage = () => {
  const dispatch = useDispatch();
  const [mounted, setMounted] = useState(false);
  const setParent = (value) => dispatch({type: ProjectActionTypes.SET_PARENT, value: value });  

  const { people } = useParams();
  const history = useHistory();

  if(!mounted){
    setParent("egitimler");  
  }

  useEffect(() =>{
    setMounted(true);
    window.scrollTo(0,0);
  },[]);  

    return(
      <React.Fragment>
        <div className="homeWrapper">
          <Header />
          <div className="newSectionWrapper">
          <GreenLine classx="spacebottom"/>
          <div className="newContentLayerWrapper">
            <div className="subContentSectionWrapper">
              <h1 className="subContentTitle">İLETİŞİM</h1>
              <p className="iletisimParagragh">
                Soru ve mesajlarınızı e-posta yoluyla bize iletebilirsiniz.<br/>
                info@sensizolmazsessizolmaz.org
              </p>

              <h4><a href="/sss" className="SSSLink">SIK SORULAN SORULAR</a></h4>

              <ul className="iletisimUl">
                <li><a target="_blank"  rel="noopener noreferrer" href="mailto:info@sensizolmazsessizolmaz.org"><i className="far fa-envelope"></i></a></li>
                <li><a target="_blank"  rel="noopener noreferrer" href="https://www.instagram.com/sensizolmazorg/"><i className="fab fa-instagram"></i></a></li>
                <li><a target="_blank"  rel="noopener noreferrer" href="https://www.youtube.com/channel/UCc1IMJQYrM7FfYs2zFgTjOA"><i className="fab fa-youtube"></i></a></li>
                <li><a target="_blank"  rel="noopener noreferrer" href="https://twitter.com/sensizolmazorg"><i className="fab fa-twitter"></i></a></li>
                <li><a target="_blank"  rel="noopener noreferrer" href="https://www.linkedin.com/company/sensizolmazorg"><i className="fab fa-linkedin"></i></a></li>
              </ul>
            </div>
 
          </div>
        </div>
          
        </div>
        <FooterNew />
      </React.Fragment>
    );
};

export default IletisimPage;