import React, { useState, useEffect } from 'react';
import {useParams, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ProjectActionTypes } from '../../redux/project/project.types';
import Header from '../../components/home/header/header.component';
import GreenLine from '../../components/greenline/greenline.component';
import FooterNew from '../../components/footernew/footernew.component';
import './egitimler.page.styles.scss';

const EgitimlerPage = () => {
  const dispatch = useDispatch();
  const [mounted, setMounted] = useState(false);
  const setParent = (value) => dispatch({type: ProjectActionTypes.SET_PARENT, value: value });  

  const { people } = useParams();
  const history = useHistory();

  if(!mounted){
    setParent("egitimler");  
  }

  useEffect(() =>{
    setMounted(true);
    window.scrollTo(0, 0);
  },[]);  

    return(
      <React.Fragment>
        <div className="homeWrapper">
          <Header />
          <div className="newSectionWrapper">
          <GreenLine classx="spacebottom"/>
          <div className="newContentLayerWrapper">
            <div className="subContentSectionWrapper">
              <h1 className="subContentTitle">EĞİTİMLER</h1>
              <p className="subContentParagraph">
              Alanında uzman kadınların medya ile ilişkilerini daha etkili ve verimli kurabilmeleri için medya eğitimleri düzenleniyor. Deneyimli medya uzmanları tarafından gönüllü kadın uzmanlar “medya nasıl çalışır, medyayı etkili ve verimli şekilde kullanmanın yolları” nelerdir konulu teorik ve pratik çalışma içeren eğitimler ile gerçekleştiriliyor. Mayıs 2021’den beri 138 kadın uzman eğitimlere katıldı.
              </p>
            </div>
            <div className="subContentSectionWrapper bgGrey spacerTop75">
              <div className="egitimGrid">
                  <div className="egitimCol">
                    <img className="egitimColImg" src="/assets/images/egitim1.jpg" alt="Medya Eğitimi 16 Ocak 2023" />
                    <p className="egitimColText">Galatasaray Üniversitesi MEDİAR işbirliğiyle kadın uzmanlarla gerçekleştirilen medya eğitimi. / 16 Ocak 2023</p>
                  </div>
                  <div className="egitimCol">
                    <img className="egitimColImg" src="/assets/images/egitim2.jpg" alt="Medya Eğitimi 7-8 Nisan 2023" />
                    <p className="egitimColText">Bahçeşehir Üniversitesi’nden kadın uzmanlarla BAU EQUAL işbirliğiyle gerçekleştirilen medya eğitimi. / 7-8 Nisan 2023</p>                    
                  </div>
                  <div className="egitimCol">
                    <img className="egitimColImg" src="/assets/images/egitim3.jpg" alt="Online Medya Eğitimi" />
                    <p className="egitimColText">Sensiz Olmaz Sessiz Olmaz veri tabanı üyelerine yönelik online medya eğitimi </p>                                        
                  </div>
              </div>

              <div className="egitimDetailWrapper">
                  <h2 className="egitimTitle">EĞİTİMLER</h2>
                  <ul className="egitimUl">
                    <li className="egitimLi">Pilot Medya Eğitim (8 – 9 Mayıs 2021)</li>
                    <li className="egitimLi">Bilim Kadınları ve Dış Politikada Kadınlar ile Medya Eğitimi (15 – 16 Mayıs 2021)</li>
                    <li className="egitimLi">Diyarbakır İş Kadınları Derneği (DİKAD) ile Medya Eğitimi (19 – 20 Haziran 2021)</li>
                    <li className="egitimLi">Türk Girişim ve İş Dünyası Konfederasyonu ile Medya Eğitimi (25 – 26 Eylül 2021) </li>
                    <li className="egitimLi">Sensiz Olmaz Sessiz Olmaz Veri Tabanı Üyelerine Yönelik Medya Eğitimi I (23 – 24 Ekim 2021)</li>
                    <li className="egitimLi">Sensiz Olmaz Sessiz Olmaz Veri Tabanı Üyelerine Yönelik Medya Eğitimi II (27 – 28 Kasım 2021)</li>
                    <li className="egitimLi">Bilim Akademisi ile Medya Eğitimi (12 – 13 Şubat 2022)</li>
                    <li className="egitimLi">Sensiz Olmaz Sessiz Olmaz Veri Tabanı Üyelerine Yönelik Medya Eğitimi III (26 – 27 Mart 2022)</li>
                    <li className="egitimLi">Sensiz Olmaz Sessiz Olmaz Veri Tabanı Üyelerine Yönelik Medya Eğitimi IV (23 – 24 Nisan 2022)</li>
                    <li className="egitimLi">Özyeğin Üniversitesi ile Medya Eğitimi (28 – 29 Mayıs 2022)</li>
                    <li className="egitimLi">Kadın Akademisyenlere Yönelik Medya Eğitimi (26 – 27 Kasım 2022)</li>
                    <li className="egitimLi">Galatasaray Üniversitesi ile Medya Eğitimi (16 – 17 Ocak 2023)</li>
                    <li className="egitimLi">Deprem Uzmanlarına Yönelik Medya Eğitimi (12 Şubat 2023) </li>
                    <li className="egitimLi">Bahçeşehir Üniversitesi ile Medya Eğitimi (7 -8 Nisan 2023)</li>
                    <li className="egitimLi">Seçim Uzmanlarına Yönelik Medya Eğitimi (29 – 30 Nisan 2023)</li>
                  </ul>
              </div>
              

            </div>      
          </div>
        </div>
          
        </div>
        <FooterNew />
      </React.Fragment>
    );
};

export default EgitimlerPage;