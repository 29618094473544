import React, { useState, useEffect } from 'react';
import {useParams, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ProjectActionTypes } from '../../redux/project/project.types';
import Header from '../../components/home/header/header.component';
import GreenLine from '../../components/greenline/greenline.component';
import FooterNew from '../../components/footernew/footernew.component';
import './farkindalikcalismalari.page.styles.scss';

const FarkindalikCalismalariPage = () => {
  const dispatch = useDispatch();
  const [mounted, setMounted] = useState(false);
  const setParent = (value) => dispatch({type: ProjectActionTypes.SET_PARENT, value: value });  

  const { people } = useParams();
  const history = useHistory();

  if(!mounted){
    setParent("egitimler");  
  }

  useEffect(() =>{
    setMounted(true);
    window.scrollTo(0, 0);
  },[]);  

    return(
      <React.Fragment>
        <div className="homeWrapper">
          <Header />
          <div className="newSectionWrapper">
          <GreenLine classx="spacebottom"/>
          <div className="newContentLayerWrapper">
            <div className="subContentSectionWrapper">
              <h1 className="subContentTitle">FARKINDALIK ÇALIŞMALARI</h1>
              <p className="subContentParagraph">
              Platformun aşağıdaki sosyal medya hesapları üzerinden kadınların<br/> medya ve kamusal alanda daha fazla görünür olmalarını teşvik eden içerikler paylaşılıyor: <br/>
<b>Twitter:</b> @sensizolmazorg<br/>
<b>Instagram:</b> sensizolmazorg<br/>
<b>LinkedIn:</b> Sensiz Olmaz Sessiz Olmaz<br/>
<b>Facebook:</b> Sensiz Olmaz Sessiz Olmaz<br/>
<b>Spotify:</b> Sensiz Olmaz Sessiz Olmaz Podcast – Sensiz Olmaz Sessiz Olmaz<br/>

              </p>
            </div>
            <div className="subContentSectionWrapper spacerTop45">
              <div className="farkindalikGrid">
                  <div className="farkindalikCol">
                    <img className="farkindalikColImg" src="/assets/images/farkindalik1.jpg" alt="" />
                  </div>
                  <div className="farkindalikCol">
                    <img className="farkindalikColImg" src="/assets/images/farkindalik2.jpg" alt="" />
                  </div>
                  <div className="farkindalikCol">
                    <img className="farkindalikColImg" src="/assets/images/farkindalik3.jpg" alt="" />
                  </div>
                  <div className="farkindalikCol">
                    <img className="farkindalikColImg" src="/assets/images/farkindalik4.jpg" alt="" />
                  </div>
                  <div className="farkindalikCol">
                    <img className="farkindalikColImg" src="/assets/images/farkindalik5.jpg" alt="" />
                  </div>
                  <div className="farkindalikCol">
                    <img className="farkindalikColImg" src="/assets/images/farkindalik6.jpg" alt="" />
                  </div>
                  <div className="farkindalikCol">
                    <img className="farkindalikColImg" src="/assets/images/farkindalik7.jpg" alt="" />
                  </div>

              </div>
            </div>      
          </div>
        </div>
        </div>
        <FooterNew />
      </React.Fragment>
    );
};

export default FarkindalikCalismalariPage;