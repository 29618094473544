import React from 'react';
import { Link } from 'react-router-dom';
import './filter.styles.scss';

const Filter = () => {
  return(
    <React.Fragment>
      <div id="filterID" className="filterSectionWrapperID">
        <div className="filterSectionWrapper">
            <h4 className="filterSectionTitle">Kadınların sesinin daha fazla duyulması için veri tabanına giriş yapın.</h4>
            <div className="filterGridWrapper">
                <Link to="/signup" className="middleRedBox">UZMANLAR<br/> ARASINA KATILIN</Link>
                <Link to="/uzman-bul" className="middleRedBox">UZMAN ARAYIN</Link>
            </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Filter;