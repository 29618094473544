import React, { useState, useEffect } from 'react';
import {useParams, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ProjectActionTypes } from '../../redux/project/project.types';
import Header from '../../components/home/header/header.component';
import GreenLine from '../../components/greenline/greenline.component';
import FooterNew from '../../components/footernew/footernew.component';
import './hakkimizda.page.styles.scss';

const HakkimizdaPage = () => {
  const dispatch = useDispatch();
  const [mounted, setMounted] = useState(false);
  const setParent = (value) => dispatch({type: ProjectActionTypes.SET_PARENT, value: value });  

  const { people } = useParams();
  const history = useHistory();
  let cv1 = false,cv2 = false,cv3 = false,cv7 = false, cv8 = false, cv9 = false;
  let nextPage = "";
  let prevPage = "";
  if (people === "ayse-karabat"){
    cv1 = true;
    nextPage = "ayse-yircali";
    prevPage = "semin-gumusel-guner";
  }else if(people === "ayse-yircali"){
    cv2 = true;
    nextPage = "berfin-coskun";
    prevPage = "ayse-karabat";    
  }else if(people === "berfin-coskun"){
    cv3 = true;
    nextPage = "ozge-genc";
    prevPage = "ayse-yircali";    
  }else if(people === "ozge-genc"){
    cv7 = true;
    nextPage = "sabiha-senyucel-gundogar";
    prevPage = "berfin-coskun";    

  }else if(people === "sabiha-senyucel-gundogar"){
    cv8 = true;
    nextPage = "semin-gumusel-guner";
    prevPage = "ozge-genc";        
  }else if(people === "semin-gumusel-guner"){
    cv9 = true;
    nextPage = "ayse-karabat";
    prevPage = "sabiha-senyucel-gundogar";        

  }else{
    cv1 = true;
    nextPage = "ayse-yircali";
    prevPage = "semin-gumusel-guner";
  }
  const [cv, setCv] = useState({
    "cv1":cv1,
    "cv2":cv2,
    "cv3":cv3,
    "cv7":cv7,
    "cv8":cv8,
    "cv9":cv9
  });

  const setHistory = (x) => {
    history.replace("/ozgecmisler/"+x);
  }

  if(!mounted){
    setParent("hakkimizda");  
  }

  const setPeople = (people) => {
    if (people === "ayse-karabat"){
      setCv({"cv1":true,"cv2":false,"cv3":false,"cv7":false,"cv8":false,"cv9":false});setHistory('ayse-karabat');
    }else if(people === "ayse-yircali"){
      setCv({"cv1":false,"cv2":true,"cv3":false,"cv7":false,"cv8":false,"cv9":false});setHistory('ayse-yircali');
    }else if(people === "berfin-coskun"){
      setCv({"cv1":false,"cv2":false,"cv3":true,"cv7":false,"cv8":false,"cv9":false});setHistory('berfin-coskun');
    }else if(people === "ozge-genc"){
      setCv({"cv1":false,"cv2":false,"cv3":false,"cv7":true,"cv8":false,"cv9":false});setHistory('ozge-genc');
    }else if(people === "sabiha-senyucel-gundogar"){
      setCv({"cv1":false,"cv2":false,"cv3":false,"cv7":false,"cv8":true,"cv9":false});setHistory('sabiha-senyucel-gundogar');
    }else if(people === "semin-gumusel-guner"){
      setCv({"cv1":false,"cv2":false,"cv3":false,"cv7":false,"cv8":false,"cv9":true});setHistory('semin-gumusel-guner');
    }
  };


  useEffect(() =>{
    setMounted(true);
    window.scrollTo(0,0);
  },[]);  

    return(
      <React.Fragment>
        <div className="homeWrapper">
          <Header />
          <div className="newSectionWrapper">
          <GreenLine classx="spacebottom"/>
          <div className="newContentLayerWrapper">
            <div className="subContentSectionWrapper">
              <h1 className="subContentTitle">HAKKIMIZDA</h1>
              <p className="subContentParagraph">
                "Sensiz Olmaz Sessiz Olmaz" projesi, Mayıs 2021'de hayata geçirildi. Çıkış amacı, kadınların fikirleri ve uzmanlıklarıyla daha görünür hale gelmesini teşvik etmek, böylece kadına yönelik ayrımcılığı azaltmaktır.          
              </p>
              <p className="subContentParagraph">
              Kadınların toplumsal ve iş yaşamındaki rollerinin daha iyi anlaşılması ve farklılık gözetmeksizin bakış açılarının kamusal alanda daha görünür olması, toplumsal cinsiyet eşitliği için ileriye dönük olumlu etkiler yaratacaktır.
              </p>
              <p className="subContentParagraph">
              Girişimin kurucuları ve üstlenicileri, medya ve sivil toplum sektörlerinde deneyimli ve çeşitli uzmanlık 
      alanlarına sahip kişilerden oluşmaktadır. Projede yer alan ekip üyeleri, kadınların seslerini duyurmak ve toplumun farklı kesimlerinden kadınları desteklemek amacıyla birlikte çalışmaktadır.
              </p>
            </div>
            <div className="subContentSectionWrapper bgGrey spacerTop75">
              <h2 className="subContentTitle2">PROJE EKİBİ</h2>  
              <ul className="ulProjeEkibi">
              <li className={`liProjeEkibi ${cv.cv1 === true ? 'selected' : ''}`} onClick={()=>{setCv({"cv1":true,"cv2":false,"cv3":false,"cv4":false,"cv5":false,"cv6":false,"cv7":false,"cv8":false,"cv9":false});setHistory('ayse-karabat');}} >Ayşe Karabat</li>
                <li className={`liProjeEkibi ${cv.cv2 === true ? 'selected' : ''}`} onClick={()=>{setCv({"cv1":false,"cv2":true,"cv3":false,"cv4":false,"cv5":false,"cv6":false,"cv7":false,"cv8":false,"cv9":false});setHistory('ayse-yircali');}} >Ayşe Yırcalı</li>
                <li className={`liProjeEkibi ${cv.cv3 === true ? 'selected' : ''}`} onClick={()=>{setCv({"cv1":false,"cv2":false,"cv3":true,"cv4":false,"cv5":false,"cv6":false,"cv7":false,"cv8":false,"cv9":false});setHistory('berfin-coskun');}} >Berfin Coşkun</li>
                <li className={`liProjeEkibi ${cv.cv7 === true ? 'selected' : ''}`} onClick={()=>{setCv({"cv1":false,"cv2":false,"cv3":false,"cv4":false,"cv5":false,"cv6":false,"cv7":true,"cv8":false,"cv9":false});setHistory('ozge-genc');}} >Özge Genç</li>
                <li className={`liProjeEkibi ${cv.cv8 === true ? 'selected' : ''}`} onClick={()=>{setCv({"cv1":false,"cv2":false,"cv3":false,"cv4":false,"cv5":false,"cv6":false,"cv7":false,"cv8":true,"cv9":false});setHistory('sabiha-senyucel-gundogar');}} >Sabiha Senyücel Gündoğar</li>
                <li className={`liProjeEkibi ${cv.cv9 === true ? 'selected' : ''}`} onClick={()=>{setCv({"cv1":false,"cv2":false,"cv3":false,"cv4":false,"cv5":false,"cv6":false,"cv7":false,"cv8":false,"cv9":true});setHistory('semin-gumusel-guner');}} >Semin Gümüşel Güner</li>
              </ul>

              <div className="sectionCVWrapper">
                  <div className="cvImageLayer">
                  {
                      cv.cv1 === true &&                    
                        <img className="cvImg" src={`/assets/images/ayse-karabat.jpg`} alt="" />
                  }
                  {
                      cv.cv2 === true &&                    
                        <img className="cvImg" src={`/assets/images/ayse-yircali.jpg`} alt="" />
                  }
                  {
                      cv.cv3 === true &&                    
                        <img className="cvImg" src={`/assets/images/berfin-coskun.jpg`} alt="" />
                  }
                  {
                      cv.cv7 === true &&                    
                        <img className="cvImg" src={`/assets/images/ozge-genc.jpg`} alt="" />
                  }
                  {
                      cv.cv8 === true &&                    
                        <img className="cvImg" src={`/assets/images/sabiha-senyucel-gundogar.jpg`} alt="" />
                  }
                  {
                      cv.cv9 === true &&                    
                        <img className="cvImg" src={`/assets/images/semin-gumusel-guner.jpg`} alt="" />
                  }                                                                                          
                  </div>
                  <div className="cvContent">
                    {
                      cv.cv1 === true &&
                      <div className="setfadein">
                        <h3 className="cvTitle">AYŞE KARABAT</h3>
                        <p className="jobWrapper">
                          Gazeteci - Yazar
                        </p>
                        <p className="cvtext">
                          ODTÜ Siyaset Bilimi Bölümünü bitirdi.  1994'ten itibaren ulusal ve uluslararası çeşitli radyo, TV ve gazeteler için çalıştı.  Gazetecilik faaliyetlerini Ortadoğu bölgesinde yoğunlaştırdı.  Bahçeşehir Üniversitesi Yeni Medya Bölümünde haber yazma dersi verdi. 
                        </p>
                        <p className="cvtext">
                          Kudüs'ün Gönüllü Sürgünleri adlı bir romanı ve Suriye Savaşları adlı bir inceleme kitabı var. Semin Gümeşel Güner ile birlikte UNFPA Azerbaycan ofisi için “Azerbaycan medyasında toplumsal cinsiyet duyarlılığını artırmak için gazeteciler için el kitabını” yazdı.
                        </p>
                      </div>
                    }
                    {
                      cv.cv2 === true &&
                      <div className="setfadein">
                        <h3 className="cvTitle">AYŞE YIRCALI</h3>
                        <p className="cvtext">
                          PAR Danışmanlık ve Araştırma'nın kurucularındandır. 2002'den itibaren sivil toplum çalışmalarında yer almış, kurucu üyesi olduğu Kamusal Politika ve Demokrasi Çalışmaları Derneği'nde (PODEM) 2015-18 yılları arasında genel direktörlük yapmıştır. 2002'de Türkiye Ekonomik ve Sosyal Etüdler Vakfı'nda (TESEV) çalışmaya başlamış, 2012-2015 arası Vakfın genel direktörü olarak görev almıştır. Bu süreçte kurumsal yönetim görevi yanında, demokratikleşme, dış politika ve iyi yönetişim alanlarında birçok ulusal ve uluslararası çapta proje ve saha çalışmasında bulunmuştur. 1997-2001 arası Global Menkul Değerler Yatırım Bankası araştırma bölümünde analist olarak çalışmıştır. Lisans seviyesinde ABD Brown Üniversitesi'nden ekonomi ve tarih dallarında çift diploma ile 1997'de mezun olmuştur. Lisansüstü diplomasını 2005 yılında Sabancı Üniversitesi Siyaset Bilimi dalında almıştır.
                        </p>
                      </div>
                    }     
                    
                    {
                      cv.cv3 === true &&
                      <div className="setfadein">
                        <h3 className="cvTitle">BERFİN COŞKUN </h3>
                        <p className="cvtext">
                          Marmara Üniversitesi Sosyoloji bölümünden 2020 Temmuz ayında mezun oldu. Öğrenciliği boyunca birçok ulusal ve uluslararası sivil toplum kuruluşunda görev aldı. Birleşmiş Milletler Dünya Gıda Programı’nın yürüttüğü bir projede proje koordinatörü olarak çalıştı. Şu an ise birçok farklı projede çeşitli görevler almaya devam etmektedir. 
                        </p>
                      </div>
                    }  






                    {
                      cv.cv7 === true &&
                      <div className="setfadein">
                        <h3 className="cvTitle">ÖZGE GENÇ</h3>
                        <p className="cvtext">
                          Türkiye'nin önde gelen düşünce kuruluşları (TESEV ve PODEM) ve uluslararası kuruluşların (UNDP) yönetici, kıdemli uzman ve danışman kadrolarında çalıştı. Demokratikleşme, İnsan Hakları, Çatışma Çözümü, Anayasa, Yargı ve İç Güvenlik Reformu, Yerel Yönetim ve Kamuoyu Çalışmaları alanlarında kapsamlı saha çalışmaları yürüttü ve derinlemesine analiz ve politika raporları yazdı. Türkiye'de hükümet, parlamento, siyasi partiler ve güvenlik kurumları, uluslararası kuruluşlar ve yerel ve uluslararası STK'lar, medya ve iş dünyasıyla etkin diyalog ve iş birliği çalışmalarına imza attı. Şu anda siyasi partiler, yerel yönetimler ve STK’lara yönelik çeşitli konularda danışmanlık veriyor. PAR Araştırma ve Danışmanlık'ın kurucuları arasında yer alıyor. 
                        </p>
                        <p>
                        Lisans eğitimini 2003 yılında ODTÜ Uluslararası İlişkiler Bölümü'nde, yüksek lisansını ise 2004 yılında University of London’a bağlı School of Oriental and African Studies’de tamamladı.
                        </p>
                      </div>
                    }             
                    {
                      cv.cv8 === true &&
                      <div className="setfadein">
                        <h3 className="cvTitle">SABİHA SENYÜCEL GÜNDOĞAR</h3>
                        <p className="cvtext">
                          Sabiha Senyücel Gündoğar, İstanbul Bilgi Üniversitesi Siyaset Bilimi ve Uluslararası İlişkiler Bölümünden mezun oldu. Yine aynı üniversitede ve Leiden Üniversitesi’nde iki ayrı yüksek lisans çalışması tamamladı. Türkiye'nin önde gelen düşünce kuruluşlarında 2002 yılından itibaren araştırmacı, yönetici ve uzman olarak görevler aldı. Uluslararası ilişkiler, toplumsal araştırmalar, göç ve mülteciler üzerine çalışmalarda bulundu. Türkiye'nin farklı bölgelerinde, Orta Doğu'da, Kafkasya'da, Avrupa'da, Amerika Birleşik Devletleri'nde ve Kanada'da kapsamlı saha araştırmalarında yer aldı. 
                        </p>
                        <p className="cvtext">
                          Kasım 2019’da kurulan, sosyal ve politik etki, analiz ve değerlendirme alanlarında çalışan özel bir girişim olan PAR Araştırma ve Danışmanlık'ın kurucu ortakları arasındadır. İstanbul Kültür Üniversitesi Uluslararası İlişkiler Bölümü'nde öğretim görevlisi olarak görev yapmakta,  sivil toplum ve uluslararası ilişkiler üzerine ders vermektedir. 
                        </p>
                      </div>
                    }    
                    {
                      cv.cv9 === true &&
                      <div className="setfadein">
                        <h3 className="cvTitle">SEMİN GÜMÜŞEL GÜNER</h3>
                        <p className="cvtext">
                          Gazeteciliğe 1998 yılında, İstanbul Üniversitesi İngilizce İktisat Fakültesi’nde öğrenciyken, o yıllara damgasını vuran tartışma programı Siyaset Meydanı’nda başladı. Yüksel lisansını Galatasaray Üniversitesi Uluslararası İlişkiler Bölümü’nde tamamladı. Aktüel, Newsweek Türkiye, Al Jazeera Türk, Habertürk gibi medya kuruluşlarında çalıştı. 2007 yılında, Türkiye Gazeteciler Cemiyeti’nin “En İyi Röportaj”, 2009 yılında da “En İyi Araştırma” ödüllerini aldı. Ayşe Karabat ile birlikte Birleşmiş Milletler Nüfus Fonu için, Azerbaycan’da toplumsal cinsiyet konusunda farkındalığı artırmak amacıyla gazetecilere yönelik bir rehber kitap yazdı. 2019’dan beri bağımsız olarak gazetecilik ve editörlük faaliyetlerini sürdürüyor.
                        </p>
                      </div>
                    }    
                                              
                  </div>                  
                  <div className="cvController">
                    <button className="btn-cv-controller" onClick={() => setPeople(prevPage)}><img src="/assets/images/arrow-left.png" alt="" /></button>
                    <button className="btn-cv-controller" onClick={() => setPeople(nextPage)}><img src="/assets/images/arrow-right.png" alt="" /></button>
                  </div>                     
              </div>  
 
            </div>      
          </div>
        </div>
        </div>
        <FooterNew />
      </React.Fragment>
    );
};

export default HakkimizdaPage;