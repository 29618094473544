import React from 'react';
import GreenLine from '../greenline/greenline.component';

import './blogcontent.style.scss';

const BlogContent = () => (
  <div className="newSectionWrapper">
    <GreenLine classx="spacebottom"/>
    <div className="newContentLayerWrapper">
      <div className="blogSectionWrapper blogbg">
        <div className="articleItem">
          <img className="blogPageImg" src="/assets/images/sessiz-olmaz-blog.jpg" alt="SENSİZ OLMAZ SESSİZ OLMAZ BLOG"/> 
        </div>
        <div className="articleItem">
          <div className="articleContentTop">
            <div className="articleContentHeader">
              <img className="articleContentBlogImg" src="/assets/images/sessiz-olmaz-logo.png" alt="BLOG"/> 
              <h2 className="articleItemTitle">BLOG</h2>
            </div>

          </div>
          <p className="articleItemContent">
            Sessiz Olmaz blogunda çeşitli alanlarda makaleler,<br/> röportajlar ve podcastler paylaşılıyor.
            <br/>
            Bloga uzmanlık alanınızdan bir yazı ile katkıda bulunmak isterseniz, lütfen bizimle iletişime geçin.
            <br/><br/>
            <a href="https://www.sessizolmaz.org" className="linkToBlog" rel="noreferrer" target="_blank">Blog için tıklayın</a>
          </p> 
        </div>          
      </div>
    </div>
  </div>
);

export default BlogContent;