import React from 'react';
import './slider.styles.scss';

const Slider = () => {
  return (
    <React.Fragment>
      <div className="sliderID">
        <div className="sliderWrapper">
          <div className="sliderQuoteWrapper">
            <span className="sliderAttachLeft"></span>
            <h2 className="sliderQuote" title="SEN OL Kİ MEDYANIN SESİ TAM OLSUN">SEN OL Kİ MEDYANIN SESİ TAM OLSUN</h2>
            <p className="sliderText">
              "Sensiz Olmaz Sessiz Olmaz" girişimi yazılı, görsel, sosyal medya ve diğer kamusal alanlarda  kadınların daha fazla görünür olmasını amaçlayan bir sosyal girişimdir.(platformdur)
            </p>
            <p className="sliderText">
            Bu girişim, televizyon, gazete, haber siteleri, sivil toplum kuruluşları veözel sektör mecraları ile etkinliklerde kadınların uzmanlıklarıyla daha fazla yer almasına  katkıda bulunmayı hedefler. 
            </p>
            <p className="sliderText">
              Aynı zamanda, kendi medya mecraları aracılığıyla toplumsal cinsiyet eşitliği konusunda şemsiyesi altında farkındalık çalışmaları yürütmektedir. 
              <span className="sliderAttachRight"></span>
            </p>
          </div>        
        </div>
      </div>
    </React.Fragment>
  );
}

export default Slider;