import React, { useState,useEffect } from 'react';
import { useLocation, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { ProjectActionTypes } from '../../redux/project/project.types';
import axios from 'axios';
import Pagination from "react-js-pagination";
import { customShowLoadingLayer,customHideLoadingLayer,showWarningLayer } from '../../utils/utils';
import * as actionProjectCreators from '../../redux/project/project.actions';
import CheckBox from '../../components/checkbox/checkbox.component';
import FooterNew from '../../components/footernew/footernew.component';
import Header from '../../components/home/header/header.component';
import 'reactjs-popup/dist/index.css';
import './searchresults.page.styles.scss';

const API_URL = process.env.REACT_APP_API_URL; 
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}

const SearchResultsPage = () => {
  let query = useQuery();
  const keywordx = query.get("keyword");
  let profession = query.get("profession")

  let page = (query.get("page") === null || query.get("page") === undefined || query.get("page") === "") ? 1 : query.get("page");
  const xprops = useSelector(state => state);
  const dispatch = useDispatch();
  const history = useHistory();

  const setExpertDetails = (expertDetails) => dispatch(actionProjectCreators.setExpertDetails(expertDetails));

  const[searchResult, setSearchResult] = useState([]);
  const[activePage, setActivePage] = useState(page);
  const[searchCount, setSearchCount] = useState(null);
  const[keyword,setKeyword] = useState(keywordx);
  const[isLoading,setIsLoading] = useState(true);
  
  const onWarning = (payload) => {
    dispatch({ type: ProjectActionTypes.SET_WARNING_MESSAGE, payload: payload});
  }
  const setSearchQueryParams = (payload) => {
    dispatch({ type: ProjectActionTypes.SET_SEARCH_QUERY_PARAMS, payload: payload});
  }

  useEffect(()=>{
    if (keywordx !== null || profession !== null){
      getSearchResults();
    }else{
      if (keywordx === null && profession === null){
        setIsLoading(false);
      }
      
    }
  },[]);

  const getSearchResults = async () => {
    setExpertDetails(null);
    const fmData = new FormData();
    const config = {
      headers: { "content-type": "multipart/form-data" },
      crossDomain: true
    }

    //profession = profession === null ? "" : profession;

    fmData.append("keyword", keywordx);    
    fmData.append("profession", profession);    
    fmData.append("page", activePage);    
    fmData.append("pagetype", "new");    
    let warnmessage = "";
    const res = await axios.post(
      API_URL+"getsearchresults",
      fmData,
      config
    );
    
    if (res.data.type === "done"){
      let tmpSearchCount = parseInt(res.data.data.total);
      if (isNaN(tmpSearchCount) || tmpSearchCount < 1){
        setSearchCount(0);
      }else{
        let tmpPage = 4 * (parseInt(activePage-1));
        console.log(tmpPage);
        console.log(tmpSearchCount);
        
        if (tmpPage > tmpSearchCount){
          setSearchCount(0);
        }else{
          setSearchCount(parseInt(tmpSearchCount));      
          //console.log(res.data.data.data);
          setSearchResult(res.data.data.data);
        }


      }
      customHideLoadingLayer();   
    }else{
      if (res.data.message === "DP_Params"){
        warnmessage = "Yanlış arama kriteri. Lütfen tekrar deneyiniz.";
      }else{
        warnmessage = "Bir hata oluştu. Lütfen tekrar deneyiniz.";
      }
      setSearchCount(0);
      
      setIsLoading(false);
      onWarning({warningMessageType:'fail', warningMessageText: warnmessage});      
      showWarningLayer(); 
      customHideLoadingLayer();   
    }    
  }


  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    filterSearch(pageNumber);
  }



  const handleChangeKeyword = (event) => {
    setKeyword(event.target.value);
  }


  const filterSearch = (pageNumber) => {

    customShowLoadingLayer();
    let warnmessage = "";
    if (keyword === null || keyword === ""){
      warnmessage = "Arama kritiri boş olamaz."
      onWarning({warningMessageType:'fail', warningMessageText: warnmessage});      
      showWarningLayer(); 
      customHideLoadingLayer();
      return;
    }

    //const profession = expert === null ? "" : expert;
    let url = "/arama-sonuclari?page="+pageNumber;

    if (keyword !== null && keyword !== ""){
      url = url + "&keyword="+keyword;
    }

    setSearchQueryParams(
      {
        page:pageNumber,
        keyword: keyword !== null ? keyword : "",
      }
    );      

    
    history.push(url);
    window.location.reload();
    
  }  

  const getExpertDetails = async (eid,ename) => {
    customShowLoadingLayer();   
    if (eid === null || eid === ""){
      customHideLoadingLayer();
      history.push("/home");
    }
    const fmData = new FormData();
    const config = {
      headers: { "content-type": "multipart/form-data" },
      crossDomain: true
    }

    fmData.append("eid", eid);    
    
    let warnmessage = "";
    const res = await axios.post(
      API_URL+"getexpertdetails",
      fmData,
      config
    );
    
    if (res.data.type === "done"){
      setExpertDetails(res.data.data);
      //dispatch(setExpertDetails)
      //console.log(res.data.data);
      customHideLoadingLayer();   
    }else{
      if (res.data.message === "DP_Empty"){
        warnmessage = "Lütfen bir uzman seçin.";
      }else{
        warnmessage = "Bir hata oluştu. Lütfen tekrar deneyiniz.";
      }
      onWarning({warningMessageType:'fail', warningMessageText: warnmessage});      
      showWarningLayer(); 
      customHideLoadingLayer();   
    }    
  }  
  
  useEffect(()=>{
    window.scrollTo(0,0);
  },[]);  

  return(
    <React.Fragment>
    <div className="homeWrapper expertsearchpage">
      <Header />
      <div className="newSectionWrapper">
      <h1 className="webSectionTitleExpert">UZMAN BUL</h1>
      <div className="newContentLayerWrapper">
        <div className="subContentSectionWrapperSearch">
          <h2 className="subContentTitle3">ARAMA KRİTERLERİ</h2>  
          <div className="sectionSearchWrapper">
              <div className="inputWrapperSearch">
                <input type="text" placeholder="Anahtar kelime" className="edits-search" value={keyword} onChange={(event)=>handleChangeKeyword(event)}  />
              </div>
              <div className="inputWrapperSearch">
                  <button className="btnSearch" onClick={()=>filterSearch(1)}>UZMAN BUL</button>
              </div>                 
            </div>          
            <div className="searchContent">
              {
                searchCount > 0 ?
                <React.Fragment>
                 <p className="searchResultInfoText">Anahtar kelime <strong>{keywordx === null ? "Belirtilmemiş" : keywordx}</strong> için <span className="expertCount">"{searchCount}"</span> uzman bulundu.</p>
                 <div className="lastExpertsContentWrapper">
                  {
                    searchResult.length > 0 &&
                      searchResult.map(({DOCUMENTID,USERID,DOCUMENTNAME,...otherProps},index) => (
                        <div key={index} className="lastExpertCard" onClick={()=>getExpertDetails(DOCUMENTID,DOCUMENTNAME)}>
                          <img className="lastExpertImg" src={`https://www.sensizolmazsessizolmaz.org/uploads/normal_profile_${USERID}.jpg`} alt={DOCUMENTNAME} onError={(e)=>{e.target.onerror = null; e.target.src="/assets/images/default-woman.jpg"}} />
                          <h4 className={`lastExpertName ${xprops.project.expertDetails !== null && xprops.project.expertDetails.USERID === USERID && "selected"}`}>{DOCUMENTNAME}</h4>
                          <h6 className={`liveInfo ${xprops.project.expertDetails !== null && xprops.project.expertDetails.USERID === USERID && "selected"}`}>{otherProps.YASADIGIULKE} / {otherProps.YASADIGISEHIR}</h6>
                        </div>
                      ))
                  }
               </div>                 
                <div className="paginationWrapper">
                 <Pagination
                    activePage={parseInt(activePage)}
                    itemsCountPerPage={4}
                    totalItemsCount={searchCount}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange}
                    disabledClass="disabled"
                    hideFirstLastPages={true}
                    prevPageText=""
                    nextPageText=""
                    itemClassPrev="pagePrev"
                    itemClassNext="pageNext"
                    itemClass="paginationLi"
                    activeClass="paginationLiActive"


                />
               </div>
                </React.Fragment> : searchCount === 0 ? <p>Aradığınız kriterlerde uzman bulunamadı. Lütfen tekrar deneyiniz.</p> : isLoading === true ? <p>Yükleniyor...</p> : <span></span>
              }
          </div>
     

          {
          xprops.project.expertDetails !== null && searchCount > 0 ?
          <React.Fragment>
          <div className="expertWrapper">
            <div className="expertList">
              <div className="expertListSticky">
                <img className="expertProfileImg" src={`https://www.sensizolmazsessizolmaz.org/uploads/normal_profile_${xprops.project.expertDetails.USERID}.jpg`} alt={xprops.project.expertDetails.FULLNAME} onError={(e)=>{e.target.onerror = null; e.target.src="/assets/images/default-woman.jpg"}} />
                <ul className="ulexpertList">
                  <li className="expertFilterTitle">
                    <h4 className="lastExpertName">{xprops.project.expertDetails.FULLNAME}</h4>
                    <p className="lastExpertTitle">{xprops.project.expertDetails.YASADIGIULKE} / {xprops.project.expertDetails.YASADIGISEHIR}</p>
                  </li>
                  <li className="expertFilterTitle">
                    <h4 className="lastExpertContactTitle">İletişim Bilgileri</h4>
                    <p className="lastExpertContactInfo">
                      {
                        xprops.project.expertDetails.ILETISIMTERCIHEPOSTAGOSTER === "1" &&
                         <span>{xprops.project.expertDetails.PROFILDEEPOSTA}</span>
                      }
                      {
                        xprops.project.expertDetails.ILETISIMTERCIHTELEFONGOSTER === "1" &&
                         <span>{xprops.project.expertDetails.PROFILDETELEFON}</span>
                      }
                      
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="expertContent">
              <h2 className="expertSectionTitle">Uzman Bilgileri</h2>
              <div>
                <ul className="expertIntroUl">
                  <li>
                    <div>
                      <strong>Çalıştığı Kurum : </strong>
                    </div>
                    <div>
                      {xprops.project.expertDetails.BAGIMSIZMI === "" ? "Belirtilmemiş" : xprops.project.expertDetails.BAGIMSIZMI === "1" ? "Bağımsız çalışıyor" : xprops.project.expertDetails.CALISTIGIKURUM === "" ? "Belirtilmemiş" : <span>{xprops.project.expertDetails.CALISTIGIKURUM} </span>} {xprops.project.expertDetails.WEBSITE !== "" && <span>&nbsp; /&nbsp; {xprops.project.expertDetails.WEBSITE} </span>}
                    </div>
                  </li>
                  <li>
                    <div>
                      <strong>Medya Deneyimi : </strong>
                    </div>
                     <div>
                     {xprops.project.expertDetails.MEDYADENEYIMIVARMI === "" ? "Belirtilmemiş" : xprops.project.expertDetails.MEDYADENEYIMIVARMI === "1" ? <CheckBox value="var" defaultChecked={true} checked={true} readonly>Var</CheckBox> : <CheckBox value="yok" defaultChecked={false} checked={false} readonly>Yok</CheckBox>}
                     </div>               
                  </li>
                  <li>
                    <div className="">
                      <strong>Konferans Deneyimi : </strong>
                    </div>
                    <div>
                      {xprops.project.expertDetails.KONFERANSDENEYIMIVARMI === "" ? "Belirtilmemiş" : xprops.project.expertDetails.KONFERANSDENEYIMIVARMI === "1" ? <CheckBox value="var" defaultChecked={true} checked={true} readonly>Var</CheckBox> : <CheckBox value="yok" defaultChecked={false} checked={false} readonly>Yok</CheckBox>}
                    </div>
                  </li>
                </ul>
              </div>
              <h2 className="expertSectionTitle">Kısa Özgeçmiş</h2>
              <p className="expertContentP">{xprops.project.expertDetails.KISAOZGECMIS !== undefined ? xprops.project.expertDetails.KISAOZGECMIS : '' }</p>
              
              <h2 className="expertSectionTitle">Çalıştığı Sektör</h2>
              <p className="expertContentP">
                {
                (xprops.project.expertDetails.CALISTIGISEKTOR !== undefined && xprops.project.expertDetails.CALISTIGISEKTOR !== "") ? 
                    xprops.project.expertDetails.CALISTIGISEKTOR : <span>Belirtilmemiş</span>
                }
              </p>                 
              <h2 className="expertSectionTitle">Uzmanlık Alanları</h2>
              <p className="expertContentP">
                {
                  xprops.project.expertDetails.UZMANLIKALANLARI !== undefined &&
                    xprops.project.expertDetails.UZMANLIKALANLARI.replaceAll("|",",")
                }
                {/* <ul>
                {
                  xprops.project.expertDetails.UZMANLIKALANLARI !== undefined &&
                    xprops.project.expertDetails.UZMANLIKALANLARI.split("|").map((value,index)=>{
                      return(
                        <li key={index}>{value}</li>
                      )
                    })
                }
                </ul> */}
              </p>              
              {/* <h2 className="expertSectionTitle">Uzman Olduğu Ülkeler</h2>
              <div className="expertContentP">
                <ul>
                {
                  xprops.project.expertDetails.UZMANOLUNANULKELER.split("|").map((value,index)=>{
                    return(
                      <li key={index}>{value}</li>
                    )
                  })
                }
                </ul>
              </div>                             */}
              {/* <h2 className="expertSectionTitle">Görüş Vermek İstediği Konular</h2>
              <div className="expertContentP">
                <ul>
                {
                  xprops.project.expertDetails.GORUSVERILECEKKONULAR.split("|").map((value,index)=>{
                    return(
                      <li key={index}>{value}</li>
                    )
                  })
                }
                </ul>
              </div>   */}
              <h2 className="expertSectionTitle">Yayın ve Referanslar</h2>
              <div className="expertContentP">
                <ul>
                {
                  xprops.project.expertDetails.YAYINVEREFERANSLAR !== ""  ?
                  xprops.project.expertDetails.YAYINVEREFERANSLAR.split("|").map((value,index)=>{
                    return(
                      <li key={index}><a className="expertpublishs" href={value} rel="noreferrer" target="_blank">{value}</a></li>
                    )
                  }) : <span>Belirtilmemiş</span>
                }
                </ul>
              </div>   
              <h2 className="expertSectionTitle">Sosyal Medya Hesapları</h2>
              <div className="expertContentP">
                <ul className="expertSocialMedyaUl">
                {
                  xprops.project.expertDetails.SOSYALMEDYAHESAPLARI !== ""  ?
                  xprops.project.expertDetails.SOSYALMEDYAHESAPLARI.split("|").map((value,index)=>{
                    return(
                      <li key={index}>
                        <div className="socialLine"><a href={value} target="_blank" rel="noreferrer"><span className="socialMedyaInfo">{value}</span></a></div>                      
                      </li>
                      
                    )
                  }) : <span>Belirtilmemiş</span>
                }
                </ul>
              </div>                                          
              <div className="clean"></div>
            </div>
          </div>          
          </React.Fragment>
          :
          <React.Fragment>
            <div className="expertLoading">
              {
                searchCount > 0 ?
                <p></p> : <span></span>
              }
              
            </div>
          </React.Fragment> 
        }



        </div>      
      </div>
    </div>
    </div>
    <FooterNew />
  </React.Fragment>


  );
};

export default SearchResultsPage;