import React from 'react';
import { Link } from 'react-router-dom';
import './aboutlayer.styles.scss';

const AboutLayer = () => {
  return(
    <React.Fragment>
    <div className="mainWorksWrapper">
      <div className="mainWorksHeader">
        <img className="mainWorksHeaderImg" src="/assets/images/middle-logo.png" alt="Sensiz Olmaz Sessiz Olmaz" />
        <h2 className="mainWorksHeaderTitle">ÇALIŞMALARI</h2>
      </div>
      
      <div className="workshopWrapper">
        <div className="workshop">
          <img className="workshop-image" src="/assets/images/soso-uye-olan-kadinlar.jpg" alt="KADIN UZMAN VERİ TABANI" />
          <div className="workshop-title-wrapper">
            <h3 className="workshop-title">KADIN UZMAN<span className="italic">VERİ TABANI</span></h3>
          </div>
          <div className="workshop-content-wrapper">
            <p className="workshop-content">
              Farklı alanlardaki kadın uzmanların profillerini oluşturabildiği ve n medya, sivil toplum ve özel sektör temsilcilerinin ulaşabileceği bir uzman havuzuoluşturuldu. Mayıs 2021’de faaliyete geçen uzman havuzunun halihazırda 345 üyesi var.
              <br/><br/>
              Sensizolmazsessizolmaz.org üzerinden uzman havuzunda yer alabilirsiniz: 
              <br/>
              <Link to="/signup">Tıklayınız</Link>
            </p>
          </div>
        </div>

        <div className="workshop">
          <img className="workshop-image" src="/assets/images/egitimler.jpg" alt="MEDYA EĞİTİMLERİ" />
          <div className="workshop-title-wrapper">
            <h3 className="workshop-title">MEDYA EĞİTİMLERİ</h3>
          </div>
          <div className="workshop-content-wrapper">
            <p className="workshop-content">
              Alanında uzman kadınların medya ile ilişkilerini daha etkili ve verimli kurabilmeleri için medya eğitimleri düzenleniyor. Deneyimli medya uzmanları tarafından gönüllü kadın uzmanlar “medya nasıl çalışır, medyayı etkili ve verimli şekilde kullanmanın yolları” nelerdir konulu teorik ve pratik çalışma içeren eğitimler ile gerçekleştiriliyor. Mayıs 2021’den beri 138 kadın uzman eğitimlere katıldı.            
            </p>
          </div>
        </div>

        <div className="workshop">
          <img className="workshop-image" src="/assets/images/kadin-uzmanlar-icin-kocluk-calismasi.jpg" alt="KADIN UZMAN VERİ TABANI" />
          <div className="workshop-title-wrapper">
          <h3 className="workshop-title">MENTORLUK<span className="italic">ÇALIŞMALARI</span></h3>
          </div>
          <div className="workshop-content-wrapper">
            <p className="workshop-content">
              Kadınların iş hayatında kariyerlerini güçlendirmelerine ve karşılaştıkları zorlukları aşmalarına yardımcı olmayı amaçlayan koçluk çalışmaları gerçekleştiriliyor. 2022-2023 yıllarında, 11 kadın uzman koçluk çalışmasından faydalandı. 
            </p>
          </div>
        </div>       
        <div className="workshop">
          <img className="workshop-image" src="/assets/images/roportaj.jpg" alt="SESSİZ OLMAZ BLOG" />
          <div className="workshop-title-wrapper">
            <h3 className="workshop-title">SESSİZ OLMAZ BLOG</h3>
          </div>
          <div className="workshop-content-wrapper">
            <p className="workshop-content">
              <a href="https://www.sessizolmaz.org"><b>https://www.sessizolmaz.org</b></a>
              <br/><br/>
              Sessiz Olmaz blogunda çeşitli alanlarda makaleler, röportajlar ve podcastler paylaşılıyor. Bloga uzmanlık alanınızdan bir yazı ile katkıda bulunmak isterseniz, lütfen bizimle iletişime geçin. 
            </p>
          </div>
        </div>
        <div className="workshop">
          <img className="workshop-image" src="/assets/images/duyuru.jpg" alt="FARKINDALIK ÇALIŞMALARI" />
          <div className="workshop-title-wrapper">
            <h3 className="workshop-title">FARKINDALIK<span className="italic">ÇALIŞMALARI</span></h3>
          </div>
          <div className="workshop-content-wrapper">
            <p className="workshop-content">
            Platformun aşağıdaki sosyal medya hesapları üzerinden kadınların medya ve kamusal alanda daha fazla görünür olmalarını teşvik eden içerikler paylaşılıyor: 
            <br/>
            <b>Twitter:</b> @sensizolmazorg <br/>
            <b>Instagram:</b> sensizolmazorg <br/>
            <b>LinkedIn:</b> Sensiz Olmaz Sessiz Olmaz <br/>
            <b>Facebook:</b> Sensiz Olmaz Sessiz Olmaz <br/>
            <b>Spotify:</b> Sensiz Olmaz Sessiz Olmaz Podcast <br/>
            </p>
          </div>
        </div>                
        <div className="workshop">
          <img className="workshop-image" src="/assets/images/toplantilar.jpg" alt="TOPLANTILAR" />
          <div className="workshop-title-wrapper">
            <h3 className="workshop-title">TOPLANTILAR</h3>
          </div>
          <div className="workshop-content-wrapper">
            <p className="workshop-content">
            Kadınları iş hayatı kariyerlerinde ilerlerken güçlendirmeyi, karşılaştıkları sorunları aşmalarına yardımcı olmayı amaçlayan koçluk çalışması gerçekleştirildi. Koçluk çalışması, Gestalt ve Erickson Koçluk okullarından mezun, 2010 yılından bu yana Uluslararası Koçluk Federasyonu üyesi profesyonel bir koç olarak çalışmakta olan Rana Beri’nin moderatörlüğünde yapıldı. 2022-2023 yıllarında 11 kadın uzman koçluk çalışmasından faydalandı. 
            </p>
          </div>
        </div>          
      </div>

    </div>

    </React.Fragment>
  );
}

export default AboutLayer;