import React from 'react';
const MenuItem = ({ pageTitle,pageLink,clickable,subItems, active }) => {
  return(
    <li className={`menuitem ${active === true ? "menuLiSelected" : ""}`}>
      {
        clickable === true ?
         <a href={`/${pageLink}`} className={`menuLinkItem ${active === true ? "menuSelected" : ""}`}>
              {pageTitle}
        </a> :
         <a href="#" className={`menuLinkItem ${active === true ? "menuSelected" : ""}`} rel="noreferrer">
              {pageTitle}
        </a>
      }
      {
        clickable === false && subItems.length > 0 &&
        <div className="dropdown-content">
          {
            subItems.map((e,i) => <a key={i} href={`/${e.pageLink}`}>{e.pageTitle}</a>)
          }
        </div>          
      }      

    </li>
  );
};

export default MenuItem;