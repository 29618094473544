export const language = {
  "default":"TR",
  "TR":{
    "name":"Türkçe",
    "alias":"turkish",
    "short":"TR",
    "keywords": {
      "home":"ANASAYFA",
      "aboutUs":"Biz Kimiz",
      "findExpert":"Uzman Bul",
      "ourEducations":"Eğitimlerimiz",
      "articles":"BLOG",
      "contactUs":"İLETİŞİM",
      "signIn":"GİRİŞ YAPIN",
      "signUp":"KATILIM",
      "keyword":"Anahtar Kelime",
      "sector":"Sektör",
      "profession":"Uzmanlık Alanı",
      "regionOfInterest":"İlgilendiği Bölge",
      "language":"Dil",
      "wantToJoinToOurExperts":"Uzmanlarımız arasına katılmak ister misiniz ?",
      "slogan1":"Sen ol ki medyanın sesi tam olsun...",
      "watchPromotionVideo":"Tanıtım videosunu izle",
      "wantToRecommendExpert":"Uzman önermek ister misiniz ?",
      "findWomanExpert":"Kadın Uzman Bulun",
      "pleaseSelectSector":"Lütfen bir sektör seçin",
      "pleaseSelectProfession":"Lütfen bir uzmanlık alanı seçin",
      "pleaseSelectRegion":"Lütfen bir bölge seçiniz",
      "pleaseSelectLanguage":"Lütfen bir dil seçiniz",
      "upcomingEducations":"Yaklaşan Eğitimler",
      "upcoming":"Yaklaşan",
      "educations":"Eğitimler",
      "featured":"Öne Çıkan",
      "lastJoined":"Son Katılan",
      "experts":"Uzmanlar",
      "lastJoinedExperts":"Son Katılan Uzmanlar",
      "getInTouch":"İletişime Geç",
      "recent":"Son Yapılan",
      "posts":"Paylaşımlar",
      "ourSponsors":"Sponsorlarımız",
      "ourSupporters":"Destekçilerimiz",
      "profile":"BİLGİLERİM",
      "messages":"Mesajlar"
      
    }
  }
}