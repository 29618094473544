import React, { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { ProjectActionTypes } from '../../redux/project/project.types';
import Header from '../../components/home/header/header.component';
import GreenLine from '../../components/greenline/greenline.component';
import FooterNew from '../../components/footernew/footernew.component';
import './toplantilar.page.styles.scss';

const ToplantilarPage = () => {
  const dispatch = useDispatch();
  const [mounted, setMounted] = useState(false);
  const setParent = (value) => dispatch({type: ProjectActionTypes.SET_PARENT, value: value });  

  if(!mounted){
    setParent("toplantilar");  
  }

  useEffect(() =>{
    setMounted(true);
    window.scrollTo(0,0);
  },[]);  

    return(
      <React.Fragment>
        <div className="homeWrapper">
          <Header />
          <div className="newSectionWrapper">
          <GreenLine classx="spacebottom"/>
          <div className="newContentLayerWrapper">
            <div className="subContentSectionWrapper">
              <h1 className="subContentTitle">TOPLANTILAR</h1>
              <p className="subContentParagraph">
              Sensiz Olmaz Sessiz Olmaz projesinin tanıtıldığı lansman toplantısı 14 Haziran 2022’de gerçekleşti. Medya sektörü, akademi, iş dünyası ve sivil toplum kuruluşlarından bilinen yüzlerin katıldığı toplantıda BBC 50:50'den Miranda Holt benzer uluslararası bir uygulama olarak BBC 50:50’ye dair deneyimlerini de paylaştı. Toplantıda Sensiz Olmaz Sessiz Olmaz projesinin kısa tanıtımıyla beraber medya eğitimlerimizde yer alan eğitmen ve katılımcılar medya eğitimi konusundaki görüşlerini aktardılar. 
              </p>
            </div>
            <div className="subContentSectionWrapper bgGrey spacerTop75">
              <div className="egitimGrid">
                  <div className="egitimCol">
                    <img className="egitimColImg" src="/assets/images/toplantilar1.jpg" alt="Lansman toplantısı katılımcıları Miranda Holt’u dinliyor." />
                    <p className="egitimColText">Lansman toplantısı katılımcıları Miranda Holt’u dinliyor.</p>
                  </div>
                  <div className="egitimCol">
                    <img className="egitimColImg" src="/assets/images/toplantilar2.jpg" alt="Seda Demiralp Sensiz Olmaz Sessiz Olmaz’ın sağladığı medya eğitimi hakkında görüşlerini paylaşıyor. " />
                    <p className="egitimColText">Seda Demiralp Sensiz Olmaz Sessiz Olmaz’ın sağladığı medya eğitimi hakkında görüşlerini paylaşıyor. </p>                    
                  </div>
                  <div className="egitimCol">
                    <img className="egitimColImg" src="/assets/images/toplantilar3.jpg" alt="Proje ekibinden Semin Gümüşel Güner ve Özge Genç Sensiz Olmaz Sessiz Olmaz projesiyle ilgili bilgi veriyor. " />
                    <p className="egitimColText">Proje ekibinden Semin Gümüşel Güner ve Özge Genç Sensiz Olmaz Sessiz Olmaz projesiyle ilgili bilgi veriyor.</p>                                        
                  </div>
              </div>

              <div className="egitimDetailWrapper">
                  <h2 className="egitimTitle">TOPLANTILAR</h2>
                  <ul className="egitimUl">
                    <li className="egitimLi">Pilot Medya Eğitim (8 – 9 Mayıs 2021)</li>
                    <li className="egitimLi">Bilim Kadınları ve Dış Politikada Kadınlar ile Medya Eğitimi (15 – 16 Mayıs 2021)</li>
                    <li className="egitimLi">Diyarbakır İş Kadınları Derneği (DİKAD) ile Medya Eğitimi (19 – 20 Haziran 2021)</li>
                    <li className="egitimLi">Türk Girişim ve İş Dünyası Konfederasyonu ile Medya Eğitimi (25 – 26 Eylül 2021) </li>
                    <li className="egitimLi">Sensiz Olmaz Sessiz Olmaz Veri Tabanı Üyelerine Yönelik Medya Eğitimi I (23 – 24 Ekim 2021)</li>
                    <li className="egitimLi">Sensiz Olmaz Sessiz Olmaz Veri Tabanı Üyelerine Yönelik Medya Eğitimi II (27 – 28 Kasım 2021)</li>
                    <li className="egitimLi">Bilim Akademisi ile Medya Eğitimi (12 – 13 Şubat 2022)</li>
                    <li className="egitimLi">Sensiz Olmaz Sessiz Olmaz Veri Tabanı Üyelerine Yönelik Medya Eğitimi III (26 – 27 Mart 2022)</li>
                    <li className="egitimLi">Sensiz Olmaz Sessiz Olmaz Veri Tabanı Üyelerine Yönelik Medya Eğitimi IV (23 – 24 Nisan 2022)</li>
                    <li className="egitimLi">Özyeğin Üniversitesi ile Medya Eğitimi (28 – 29 Mayıs 2022)</li>
                    <li className="egitimLi">Kadın Akademisyenlere Yönelik Medya Eğitimi (26 – 27 Kasım 2022)</li>
                    <li className="egitimLi">Galatasaray Üniversitesi ile Medya Eğitimi (16 – 17 Ocak 2023)</li>
                    <li className="egitimLi">Deprem Uzmanlarına Yönelik Medya Eğitimi (12 Şubat 2023) </li>
                    <li className="egitimLi">Bahçeşehir Üniversitesi ile Medya Eğitimi (7 -8 Nisan 2023)</li>
                    <li className="egitimLi">Seçim Uzmanlarına Yönelik Medya Eğitimi (29 – 30 Nisan 2023)</li>
                  </ul>
              </div>
            </div> 
            <br/><br/>     
          </div>
        </div>
        </div>
        <FooterNew />
      </React.Fragment>
    );
};

export default ToplantilarPage;