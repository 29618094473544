import React, { useState, useEffect } from 'react';
import { useLocation, Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { customShowLoadingLayer,customHideLoadingLayer,showWarningLayer, hideWarningLayer,validateEmail, useFocus } from '../../utils/utils';
import NavBar from '../../components/home/navbar/navbar.component';
import FooterNew from '../../components/footernew/footernew.component';

import { ProjectActionTypes } from '../../redux/project/project.types';
import { LoginActionTypes } from '../../redux/login/login.types';
import * as actionCreators from '../../redux/login/login.actions';
import * as actionDefinitionCreators from '../../redux/definitions/definitions.actions';
import './giris.styles.scss';


const API_URL = process.env.REACT_APP_API_URL; 

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}

const GirisPage = () => {
  const xprops = useSelector(state => state);
  const dispatch = useDispatch();
  let history = useHistory();
  
  const [emailRef, setEmailFocus] = useFocus();
  const [passwordRef, setPasswordFocus] = useFocus();
  //const [loginRef, setLoginFocus] = useFocus();
  const loginFormType = "user";
  const [formType,setFormType] = useState(loginFormType);
  const [showForgetPassword, setForgetPassword] = useState(false);
  const [username,setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [lostemail,setLostemail] = useState("")


  const handleChangeUsername = (e) => setUsername(e.target.value);
  const handleChangePassword = (e) => setPassword(e.target.value);
  const handleChangeLostEmail = (e) => setLostemail(e.target.value);
  
  const handleToggleForgetPassword = () => setForgetPassword(!showForgetPassword);

  const onWarning = (payload) => {
    dispatch({ type: ProjectActionTypes.SET_WARNING_MESSAGE, payload: payload});
  }

  const onSuccess = (loginData) => {
    dispatch({ type: LoginActionTypes.LOGIN_SUCCESS, loginData: loginData});
  }

  

  const openModal = () => dispatch(actionCreators.setFormLoginStatus(true));
  const closeModal = () => dispatch(actionCreators.setFormLoginStatus(false));

  const logOut = () => dispatch(actionCreators.userLogout());
  const handleLogout = (event,isOpenLogin,isOpenMessage) => {
    customShowLoadingLayer();  
    onWarning({warningMessageType:'success', warningMessageText: 'Çıkış yapıldı.'});
    showWarningLayer();
    logOut();
    setTimeout(() => {
      if (isOpenMessage === true){
        dispatch(actionCreators.setChatMessageAfterLogin(true));
      }
      if (isOpenLogin){
        openModal();
      }
      customHideLoadingLayer();  
      hideWarningLayer();
    },3000);
  } 

  const resetPassword = async () => {
    customShowLoadingLayer(); 
    const fmData = new FormData();
    if (lostemail !== null && validateEmail(lostemail)){
      fmData.append("email", lostemail);
      const config = {
        headers: { "content-type": "multipart/form-data" },
        crossDomain: true
      }
  
      const res = await axios.post(
        "https://www.sensizolmazsessizolmaz.org/html/do_forgetpassword.php",
        //"http://localhost/soso/new-participant-form",
        fmData,
        config
      );
      if (res.data.type === "error"){
        if (res.data.message === "DP_Session"){
          logOut();
          customHideLoadingLayer(); 
        }else if (res.data.message === "DP_Email"){
          onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen e-posta adresini kontrol edin.'});
          showWarningLayer();
          customHideLoadingLayer(); 
        }else{
          onWarning({warningMessageType:'fail', warningMessageText: 'Bir hata oluştu. Lütfen tekrar deneyiniz.'});
          showWarningLayer();
          customHideLoadingLayer();
        }
      } else{
        if (res.data.message === "DP_Done"){
          onWarning({warningMessageType:'done', warningMessageText: 'Şifre resetleme linki e-posta adresinize gönderildi.'});
          showWarningLayer();
          customHideLoadingLayer();
        }else{
          onWarning({warningMessageType:'fail', warningMessageText: 'Bir hata oluştu. Lütfen tekrar deneyiniz.'});
          showWarningLayer();
          customHideLoadingLayer();
        }
      }   
      


    }else{
      onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen e-posta adresini kontrol edin.'});
      customHideLoadingLayer();
      showWarningLayer();
      return false;
    }
  }  

  const login = async (goToMessage) => {
    customShowLoadingLayer();  
    let warnmessage = "";
   
    if (username === "" || username === undefined || username === null ){
      warnmessage = "Lütfen e-posta adresinizi kontrol edin.";
      onWarning({warningMessageType:'fail', warningMessageText: warnmessage});      
      showWarningLayer();
      setEmailFocus();
      customHideLoadingLayer();      
      return;    
    }else{
      if (!validateEmail(username)){
        warnmessage = "Lütfen e-posta adresinizi kontrol edin.";
        onWarning({warningMessageType:'fail', warningMessageText: warnmessage});      
        showWarningLayer();
        setEmailFocus();
        customHideLoadingLayer();      
        return;        
      }
    }    

    if (password === "" || password === undefined || password === null ){
      warnmessage = "Lütfen şifre alanını kontrol edin.";
      onWarning({warningMessageType:'fail', warningMessageText: warnmessage});      
      showWarningLayer();
      setPasswordFocus();
      customHideLoadingLayer();      
      return;    
    }
    
    

    const fmData = new FormData();
    fmData.append("username", username);
    fmData.append("password", password);
    fmData.append("utype",loginFormType);
    const config = {
      headers: { "content-type": "multipart/form-data" },
      crossDomain: true
    }
    const loginResult = await axios.post(`https://www.sensizolmazsessizolmaz.org/html/do_actionlogin.php`,
          fmData,
          config);

    if (loginResult.data.type === "success"){
      customShowLoadingLayer();
      onSuccess(loginResult.data.data);
      warnmessage = "Giriş yapıldı.";
      if (xprops.login.chatMessageAfterLogin === true){
        dispatch(actionDefinitionCreators.setFormChatStatus(true));
        dispatch(actionCreators.setChatMessageAfterLogin(false));
      }
      onWarning({warningMessageType:'success', warningMessageText: warnmessage});      
      showWarningLayer();
      // if (loginFormType !== "medya"){
      history.push('/');
      history.replace("/home");
      // }
    }else{
      onWarning({warningMessageType:'fail', warningMessageText: 'Lütfen kullanıcı adı veya şifrenizi kontrol edin.'});
      showWarningLayer();
      customHideLoadingLayer();
    }    
    customHideLoadingLayer();
  }

  useEffect(() =>{
    window.scrollTo(0, 0);
  },[]);    

  return(
    <React.Fragment>
      <div className="homeWrapper">
        <NavBar menutype="nosticky" />      
        <div className="newSectionWrapper">
          <div className="newContentWrapper">
            <div className="loginContentWrapper">
              <div className="loginSection">
                <h4 className="login-label">E-Posta Adresi :</h4>
                <input ref={emailRef} type="text" placeholder="Lütfen e-posta adresinizi giriniz." className="edits-login" value={username} onChange={handleChangeUsername} onKeyPress={(e)=> e.key === 'Enter' && setPasswordFocus()} />
                <h4 className="login-label">Şifre :</h4>
                <input ref={passwordRef}  type="password" placeholder="Şifrenizi giriniz." className="edits-login" value={password} onChange={handleChangePassword} onKeyPress={(e)=> e.key === 'Enter' && login()}  />
                <p className="sifremiUnuttumWrapper"><Link className="linkForgetPassword" to="/sifremi-unuttum">Şifremi Unuttum</Link></p>
                <div className="loginSectionFooter">
                  <div className="loginSectionFooterLeft">
                    Hesabınız yok mu ? <Link to='/signup' className="linkForgetPassword linkForgetPasswordSignUp">Kayıt Ol</Link>
                  </div>
                  <div className="loginSectionFooterRight">
                    <button className="btnWeb lastExpertDetailBtn btnLogin" onClick={login}>GİRİŞ YAP</button>
                  </div>                

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterNew/>
      
    </React.Fragment>
  );
}

export default GirisPage;