import React from 'react';
import MenuItem from '../menuitem/menuitem.component';
import './menu.styles.scss';

const menuItems = [
  {
    pageTitle: "UZMAN BUL",
    pageLink: "uzman-bul",
    clickable: true,
    subItems:[]
  },
  {
    pageTitle:"HAKKIMIZDA",
    pageLink:"hakkimizda",
    clickable: true,
    subItems:[]
  },
  {
    pageTitle:"BLOG",
    pageLink:"blog",
    clickable: true,
    subItems:[]
  },
  {
    pageTitle:"ÇALIŞMALAR",
    pageLink:"blog",
    clickable: false,
    subItems:[
      {
        pageTitle: "EĞİTİMLER",
        pageLink: "egitimler",
        clickable:true,
        subItems:[]
      },
      {
        pageTitle: "TOPLANTILAR",
        pageLink: "toplantilar",
        clickable:true,
        subItems:[]
      },
      {
        pageTitle: "FARKINDALIK ÇALIŞMALARI",
        pageLink: "farkindalik-calismalari",
        clickable:true,
        subItems:[]
      },
      {
        pageTitle: "MENTORLUK",
        pageLink: "mentorluk",
        clickable:true,
        subItems:[]
      }            
    ]
  },
  {
    pageTitle:"İLETİŞİM",
    pageLink:"iletisim",
    clickable: true,
    subItems:[]
  }  
]; 
const Menu = (props) => {
  const menuList = menuItems.map((e, i) => 
    <MenuItem pageTitle={e.pageTitle} pageLink={e.pageLink} clickable={e.clickable} subItems={e.subItems}  active={props.activeMenu !== undefined ? true : false} key={`menuitem_${i}`} />
  )  
  
  return(
    <ul className="bottomMenu">
      {menuList}
    </ul>
  );
}

export default Menu;