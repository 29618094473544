import React, { useState, useEffect } from 'react';
import {useParams, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ProjectActionTypes } from '../../redux/project/project.types';
import Header from '../../components/home/header/header.component';
import GreenLine from '../../components/greenline/greenline.component';
import FooterNew from '../../components/footernew/footernew.component';
import './mentorluk.page.styles.scss';

const MentorlukPage = () => {
  const dispatch = useDispatch();
  const [mounted, setMounted] = useState(false);
  const setParent = (value) => dispatch({type: ProjectActionTypes.SET_PARENT, value: value });  

  const { people } = useParams();
  const history = useHistory();

  if(!mounted){
    setParent("mentorluk");  
  }

  useEffect(() =>{
    setMounted(true);
    window.scrollTo(0,0);
  },[]);  

    return(
      <React.Fragment>
        <div className="homeWrapper">
          <Header />
          <div className="newSectionWrapper">
          <GreenLine classx="spacebottom"/>
          <div className="newContentLayerWrapper">
            <div className="subContentSectionWrapper">
              <h1 className="subContentTitle">MENTORLUK</h1>
              <img className="imgMentorluk" src="/assets/images/mentorluk.jpg" alt="MENTORLUK" />
              <p className="subContentParagraph2">
              “Kendinizi yetersiz hissettiğiniz, ifade etmekte zorlandığınız veya ifade etmekten kaçındığınız durumlarla karşılaşıyor musunuz? Ast-üst ilişki yönetimi, ücret görüşmeleri, terfi konuşmalarını yönetmek veya iş-yaşam dengenizi olumlu yönde değiştirecek farklı taleplerinizi dile getirmek için desteğe mi ihtiyacınız var? İster özel sektörde, ister akademide, ister sivil toplumda olsun, iş ortamınız, çalışma arkadaşlarınız sizi strese sokuyor mu?<br/>
Eğer bu sorular hayatınızı ve kariyerinizi olumsuz etkiliyorsa, Sensiz Olmaz Sessiz Olmaz’ın veri tabanında yer alan üye kadın uzmanlar için sunduğu koçluk çalışmasından yararlanabilirsiniz.”<br/><br/>

Sensiz Olmaz Sessiz Olmaz platformu medya eğitimlerinin yanı sıra 2022’de kadınları iş hayatı kariyerlerinde güçlendirme amacı ile koçluk çalışması başlattı. Çalışma ile belirli sayıda kadın uzmana iş hayatlarında karşılaştıkları herhangi bir konuda destek verilmesi hedeflendi. Terfi görüşmesi yapma, hakkını arama, sunum yaparken rahat hissetme ve benzeri konularda çalışma yapmak isteyen 11 kadın uzman çalışmada yer aldı. Katılımcılar Sensiz Olmaz Sessiz Olmaz veri tabanına üye uzman kadınlar arasından seçildi.<br/> 
Katılımcılar kişiye ve kendi seçtikleri konuya özel birebir yürütülen bir koçluk çalışmasına dahil oldular. Koçluk çalışması Profesyonel Koç Rana Beri, MCC* ile yapılan iki seanstan oluştu.<br/><br/> 

<b>*Rana Beri hakkında:</b><br/>
Gestalt ve Erickson Koçluk okullarından mezun olan Rana Beri, 2010 yılından bu yana Uluslararası Koçluk Federasyonu üyesi profesyonel bir koç olarak çalışmakta.<br/><br/>
 
Kendisi 2500 saatten fazla koçluk deneyimi ile Master Certified Coach (MCC) ünvanına sahip.<br/>
Bireyler ve gruplarla anda kalarak değerlerini ve yaşam amaçlarını fark etme ve yaşamlarına bu farkındalığı yansıtma konusunda çalışmakta.<br/> 
Anda Kalmak ( 2018) ve İlişkilerde Anda Kalmak (2020) kitapları şu ana kadar birçok okurla buluştu. Yazar ve yazarın çalışmalarıyla ilgili daha detaylı bilgiye www.ranaberi.com adresinden ulaşabilirsiniz.


              </p>
            </div>
          </div>
        </div>
        </div>
        <FooterNew />
      </React.Fragment>
    );
};

export default MentorlukPage;